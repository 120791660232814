































import {Component, Vue} from 'vue-property-decorator'
import CreateTournamentButton from "@/components/Tournaments/CreateTournamentButton.vue";
import {RouteNames} from "@/router/types";
import ResponsiveContainer from "@/components/Shared/ResponsiveContainer.vue";
import BaseButtonRounded from "@/components/Shared/Buttons/BaseButtonRounded.vue"

@Component({
  components: {ResponsiveContainer, CreateTournamentButton, BaseButtonRounded},
  enums: {RouteNames}
})
export default class TournamentsPage extends Vue {

}
