







import Vue from "vue";
import {Component} from "vue-property-decorator";
import {RouteNames} from "@/router/types";

@Component({
  enums: {
    RouteNames
  }
})
export default class CreateTournamentButton extends Vue {

}
